import {FC} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import {Calendar} from "../components/Calendar/Calendar";
import {Mirror} from "../Mirror/Mirror";


export const AppRouter: FC = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Calendar/>}/>
                <Route path="/mirror" element={<Mirror/>}/>
            </Routes>
        </BrowserRouter>
    )
}

import React, {useEffect, useState} from "react";

import './Mirror.css'


export const ShowMirror = (props: any) => {


    return <div className="mirror">
        <div className='clock'>7:00</div>
        <div className="skrypt">
            {props.data.map((item: any) => (
                <p className="current-task">{item[2]} {item[3]}</p>
                ))}
        </div>
    </div>

}

import React from 'react';
import './App.css';
import {Calendar} from "./components/Calendar/Calendar";
import {BrowserRouter} from "react-router-dom";
import {AppRouter} from "./Routers/Router";


function App() {
    return <>
        <AppRouter/>
    </>
}


export default App;

import React, {useEffect, useState} from "react";
import {ShowMirror} from "./ShowMirror";

import './Mirror.css'



// @ts-ignore
export const Mirror = props => {

    const [date, setDate]: any = useState(new Date());
    const [data, setData] = useState([]);




    const refresh: any = async () => {
        const array: any[] = [];

        const res = await fetch(`http://localhost:3001/date/${date.toLocaleDateString()}`)
        const {data} = await res.json();

        for (let i = 0; i < data.length; i++) {
            array.push([data[i].id, data[i].start, data[i].end, data[i].title]);
        }


        console.log(date)
        // @ts-ignore
        setData(array);

    };


    useEffect(() => {

        refresh();

    }, [data])

return <ShowMirror data={data}/>

}
